.auth-container {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .google-sign-in {
    background-color: #db4437;
    border: none;
  }
  
  .google-sign-in:hover {
    background-color: #c1351d;
  }
  
  p {
    text-align: center;
  }
  
  p button {
    background: none;
    border: none;
    color: #007bff;
    cursor: pointer;
  }
  
  p button:hover {
    text-decoration: underline;
  }
  