.calendar-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 800px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.sidebar button:hover {
  background-color: #218838;
}

.sidebar button:active {
  background-color: #1e7e34;
}

.react-calendar {
  width: 100%;
  border: none;
  font-family: 'Arial, Helvetica, sans-serif';
}

.checked {
  background-color: #28a745 !important;
  color: black !important;
}

.unchecked {
  background-color: #d9534f !important;
  color:  black !important; /* Ensure text color is readable */
}

.react-calendar__tile--now {
  background: #ffc107;
  color: black !important; /* Ensure text color is readable */
}

.react-calendar__tile--active {
  background: #28a745;
  color: black !important;
}

.react-calendar__tile--hover {
  background: #007bff;
  color: blue !important;
}

.react-calendar__month-view__days__day--weekend {
  color: #d9534f;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #c0c0c0;
}
 
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  padding: 10px;
  background: none;
  font-family: inherit;
  font-size: 16px;
  cursor: pointer;
  color: black;
}

.react-calendar button:enabled:hover {
  background-color: #65f662;
  color: black;
}

.react-calendar button:enabled:active {
  background-color: #28dc25;
  color: black;
}
