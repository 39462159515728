.statistics-container {
    padding: 20px;
  }
  
  .statistics-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .statistics-table th, .statistics-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .statistics-table th {
    background-color: #f2f2f2;
    color: black;
    font-weight: bold;
  }
  
  .statistics-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .statistics-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .btn {
    display: inline-block;
    padding: 10px 20px;
    margin-bottom: 20px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #fff;
    background-color: #4CAF50;
    border: none;
    border-radius: 15px;
    box-shadow: 0 9px #999;
  }
  
  .btn:hover {background-color: #3e8e41}
  
  .btn:active {
    background-color: #3e8e41;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
  