.weekly-calendar-grid {
    margin: 20px auto;
    max-width: 800px;
  }
  
  .weekly-calendar-grid table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .weekly-calendar-grid th, .weekly-calendar-grid td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .weekly-calendar-grid th {
    background-color: #f2f2f2;
  }
  
  
  .score-table {
    margin-top: 20px;
  }
  